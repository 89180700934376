/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { Redirect } from 'react-router-dom';

import { Stage } from 'app/checkout/elements';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton
} from 'app/common/components';
import { ResolutionContextType } from 'app/common/constants';
import {
  useCurrentApplication,
  useFormatMessage,
  useNationalSiteContext
} from 'app/common/hooks';
import { StoreLocation } from 'app/store-location/components';

import messages from './LocationInfo.messages';
import { ChooseLocationButton } from 'app/store-location/components/ChooseLocationButton';
import { get } from 'lodash';

const LocationInfo = ({ active, stageNumber = 1 }) => {
  const formatMessage = useFormatMessage();
  const dealer = useCurrentApplication() || {};
  const { dealerSelected, resolutionContext } = useNationalSiteContext();

  if (active && resolutionContext === ResolutionContextType.DEALER) {
    return <Redirect to="/checkout/fulfillment-info" push />;
  }
  const displayName = get(dealer, 'displayName');

  return (
    <Stage
      active={active}
      completed={dealerSelected}
      heading={formatMessage(messages.title)}
      number={stageNumber}
    >
      {active ? (
        !dealerSelected ? (
          <>
            <p className="text-sm mb-4">
              {formatMessage(messages.noDealerCaveat)}
            </p>
            <ChooseLocationButton
              label={formatMessage(messages.selectDealer)}
            />
          </>
        ) : (
          <>
            <StoreLocation.Address {...dealer} companyName={displayName} />
            <ChooseLocationButton
              label={formatMessage(messages.changeDealer)}
            />

            <div className="flex justify-end mt-1">
              <PrimaryButton to="/checkout/fulfillment-info">
                {formatMessage(messages.nextStageButtonLabel)}
              </PrimaryButton>
            </div>
          </>
        )
      ) : (
        <section className="w-full mb-2">
          <StoreLocation.Address {...dealer} companyName={displayName} />
          {resolutionContext !== ResolutionContextType.DEALER && (
            <div className="flex justify-end mt-1">
              <SecondaryButton
                to="/checkout/location-info"
                size={TertiaryButton.Size.SMALL}
              >
                {formatMessage(messages.changeDealer)}
              </SecondaryButton>
            </div>
          )}
        </section>
      )}
    </Stage>
  );
};

export default LocationInfo;
export { LocationInfo };
