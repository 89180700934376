/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { findIndex, flatMap, omit, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { default as qs } from 'query-string';
import { withRouter } from 'react-router-dom';

import { Dropdown } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from './ResultsSortSelector.messages';

/**
 * Render component for the browse and search sort selector.
 *
 * @visibleName Browse and Search Results Sort Selector
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const ResultsSortSelector = ({
  browse,
  history,
  location,
  sorts,
  showAvailabilitySort
}) => {
  const formatMessage = useFormatMessage();
  const options = ResultsSortSelector.deriveSelectOptions(
    sorts,
    formatMessage,
    browse,
    showAvailabilitySort
  );
  const [activeOption, setActiveOption] = React.useState(0);

  React.useEffect(() => {
    setActiveOption(findIndex(options, ['active', true]));
  }, [options]);

  return (
    <section className="flex flex-wrap items-center justify-start mb-2 sm:mb-4 md:mb-6">
      <span
        id="search-results-sort-selector-label"
        className="mr-2 font-bold leading-loose"
      >
        {formatMessage(messages.label)}
      </span>
      <Dropdown hoverable>
        <Dropdown.Menu.Trigger
          aria-labelledby="search-results-sort-selector-label search-results-sort-selector-trigger"
          id="search-results-sort-selector-trigger"
          triggerClassName="pl-1 pr-2 text-primary-600 font-medium rounded hover:text-primary-800 active:text-primary-600 focus:outline-none focus:shadow-outline"
        >
          <span>{options[activeOption].label}</span>
        </Dropdown.Menu.Trigger>
        <Dropdown.Menu aria-labelledby="search-results-sort-selector-label">
          {options.map((option, i) => {
            const { label, value } = option;
            return (
              <Dropdown.Menu.Item
                isActive={activeOption === i}
                key={value}
                onClick={() => {
                  let params = qs.parse(location.search);

                  if (value === 'relevance') {
                    params = omit(params, ['sort']);
                  } else {
                    params.sort = value;
                  }

                  history.push({
                    pathname: location.pathname,
                    hash: location.hash,
                    search: qs.stringify(params),
                    state: location.state
                  });
                }}
              >
                {label}
              </Dropdown.Menu.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </section>
  );
};

/**
 * Derives the options to provide to the `Select` component from the
 * `SortOptions` applicable to the results as provided by the Search API. Each
 * `SortOption` has 2 corresponding select options: One for ascending and one
 * for descending order. Each select option has the form:
 *
 * ```
 * {
 *   active: boolean,
 *   label: string,
 *   value: {fieldName},{sortOrder}
 * }
 * ```
 *
 * @param {Array} sorts - Array of SortResponses from the Search API.
 * @param {function} formatMessage - Function to allow localizing the label.
 *     This takes the `SortOption#label` and appends the sort order:
 *     Name Ascending, Name Descending, etc.
 * @param {boolean} [browse=false] - Whether in the category browse context
 *
 * @return {Array} Array of select options derived from `sorts`
 */
ResultsSortSelector.deriveSelectOptions = (
  sorts,
  formatMessage,
  browse = false,
  showAvailabilitySort
) => {
  const activateDefault = findIndex(sorts, ['active', true]) === -1;
  const init = browse
    ? []
    : [
        {
          active: activateDefault,
          label: formatMessage(messages.defaultSortLabel),
          value: 'relevance'
        }
      ];
  return init.concat(
    flatMap(
      orderBy(
        sorts,
        ['sortOption.displayOrder', 'sortOption.active', 'sortOption.name'],
        ['asc', 'desc', 'asc']
      ),
      ({ active = false, descending = false, sortOption }) => {
        const { label, name } = sortOption;

        // Override the default functionality that generates an ascending and descending sort for
        // availability so it only shows one "Available Stocking" option that is descending.
        if (
          name === 'inventoryAvailableForApplicationScopes' &&
          showAvailabilitySort
        ) {
          return [
            {
              active: !!active && !!descending,
              label: formatMessage(messages.availableStockingOverrideLabel),
              value: `${name},desc`
            }
          ];
        }

        return [
          {
            active: !!active && !!descending,
            label: formatMessage(messages.sortValueLabel, {
              label,
              descending: true
            }),
            value: `${name},desc`
          },
          {
            active: !!active && !descending,
            label: formatMessage(messages.sortValueLabel, {
              label,
              descending: false
            }),
            value: `${name},asc`
          }
        ];
      }
    )
  );
};

ResultsSortSelector.propTypes = {
  /** Whether in the category browse context as oppposed to search */
  browse: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object
  }).isRequired,
  /** Array of sort options applicable to the browse or search results */
  sorts: PropTypes.arrayOf(
    PropTypes.shape({
      /** Whether this is the currently active sort option */
      active: PropTypes.bool,
      /** Whether the option is being used to sort in descending order */
      descending: PropTypes.bool,
      sortOption: PropTypes.shape({
        /** Display label for the option */
        label: PropTypes.string.isRequired,
        /** Value to use on API requests when the option is selected */
        name: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

export default withRouter(ResultsSortSelector);
export { ResultsSortSelector };
