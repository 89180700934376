import React from 'react';
import { CheckoutContext } from 'app/checkout/contexts';
import { get } from 'lodash';
import { CartContext } from 'app/common/contexts';
import { withRouter } from 'react-router-dom';
import { FULFILLMENT_OPTIONS_PATH } from 'app/checkout/components/CheckoutLayout/CheckoutPaths';
import goToNextCheckoutStep from 'app/checkout/components/CheckoutLayout/components/hooks/useGoToNextStep';
import { DeliveryOption } from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/components/DeliveryOptions';
import { SecondaryButton } from 'app/common/components';
import messages from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/FulfillmentInfoWithOptions.messages';
import { useFormatMessage } from 'app/common/hooks';

const DeliveryOptionsRead = ({ disabled, history }) => {
  const { isSubmittingOrder } = React.useContext(CheckoutContext);
  const { cart } = React.useContext(CartContext);
  const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
  const formatMessage = useFormatMessage();
  return (
    <div className="flex flex-col w-full">
      <div className="flex">
        <section className="flex flex-col w-full mb-2 md:flex-row pt-3">
          <section>
            <h3 className="mt-2 mb-1 text-base text-gray-900 font-bold md:mt-0">
              {formatMessage(messages.deliveryMethod)}
            </h3>
            <div>
              <SelectedFulfillmentOption fulfillmentGroup={fulfillmentGroup} />
            </div>
          </section>
        </section>
        <EditFulfillmentOptionButton
          history={history}
          disabled={disabled || isSubmittingOrder}
        />
      </div>
    </div>
  );
};

const EditFulfillmentOptionButton = ({ history, disabled }) => {
  const formatMessage = useFormatMessage();
  const { isSubmittingOrder } = React.useContext(CheckoutContext);
  const goToDeliveryEdit = goToNextCheckoutStep(
    history,
    FULFILLMENT_OPTIONS_PATH,
    {
      editing: false
    }
  );
  return (
    <footer className="flex justify-end items-end">
      <SecondaryButton
        onClick={goToDeliveryEdit}
        size={SecondaryButton.Size.SMALL}
        disabled={disabled || isSubmittingOrder}
      >
        {formatMessage(messages.edit)}
      </SecondaryButton>
    </footer>
  );
};

const SelectedFulfillmentOption = ({ fulfillmentGroup }) => {
  const { pricedFulfillmentOption } = fulfillmentGroup;
  if (pricedFulfillmentOption == null) {
    return null;
  }
  return <DeliveryOption option={pricedFulfillmentOption} />;
};

const DeliveryOptionsReadOnly = withRouter(DeliveryOptionsRead);

export default DeliveryOptionsReadOnly;
