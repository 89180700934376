/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { CheckoutContext } from 'app/checkout/contexts';
import { SecondaryButton } from 'app/common/components';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

import { Billing, Method } from './components';
import messages from './PaymentFormReadOnly.messages';
import { useBillingAddress } from '../../hooks';

const PaymentFormReadOnly = ({
  disabled = false,
  history,
  paymentMethodAttributes,
  type
}) => {
  const formatMessage = useFormatMessage();
  const { cart } = React.useContext(CartContext);
  const { isSubmittingOrder } = React.useContext(CheckoutContext);
  const { address: billingAddress } = useBillingAddress(cart);

  return (
    <>
      <section className="flex flex-col w-full mb-2 md:flex-row md:mb-0">
        <PaymentFormReadOnly.Method
          className="w-full text-sm md:w-1/2 md:pr-1"
          attributes={paymentMethodAttributes}
          type={type}
        />
        {!isEmpty(billingAddress) && (
          <PaymentFormReadOnly.Billing
            className="w-full text-sm md:w-1/2"
            billingAddress={billingAddress}
          />
        )}
      </section>
      <footer className="flex justify-end items-end">
        <SecondaryButton
          onClick={() => {
            const state = get(history, 'location.state', {});
            history.push('/checkout/payment-info', {
              ...state,
              editing: true
            });
          }}
          size={SecondaryButton.Size.SMALL}
          disabled={disabled || isSubmittingOrder}
        >
          {formatMessage(messages.edit)}
        </SecondaryButton>
      </footer>
    </>
  );
};

PaymentFormReadOnly.propTypes = {
  disabled: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.object
    }).isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};

PaymentFormReadOnly.Billing = Billing;
PaymentFormReadOnly.Method = Method;

export default withRouter(PaymentFormReadOnly);
export { PaymentFormReadOnly };
