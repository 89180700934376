/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { LocaleContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';

function useFulfillmentInfoValidationSchema(messages) {
  const { currentLocale } = useContext(LocaleContext);
  const formatMessage = useFormatMessage();
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    setSchema(
      Yup.object().shape({
        orderInstructions: Yup.string().max(
          1000,
          formatMessage(messages.orderInstructionsLengthInvalid)
        )
      })
    );
  }, [currentLocale, formatMessage, messages]);

  useEffect(() => {
    setSchema(
      Yup.object().shape({
        purchaseOrderNumber: Yup.string().max(
          20,
          formatMessage(messages.purchaseOrderNumberLengthInvalid)
        )
      })
    );
  }, [currentLocale, formatMessage, messages]);

  return schema;
}

export default useFulfillmentInfoValidationSchema;
