/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { SecondaryButton } from 'app/common/components';
import { CheckoutContext } from 'app/checkout/contexts';
import {
  useCurrentApplication,
  useFormatMessage,
  useHistory,
  useNationalSiteContext
} from 'app/common/hooks';

import { PassthroughPaymentInfoEditable } from './components';
import messages from './PassthroughPaymentInfoStage.messages';

const PassthroughPaymentInfoStage = ({
  active = false,
  completed = false,
  isPayInStore = true
}) => {
  return active || !completed ? (
    <PassthroughPaymentInfoStage.Editable
      active={active}
      completed={completed}
      isPayInStore={isPayInStore}
    />
  ) : (
    <PassthroughPaymentInfoStage.ReadOnly />
  );
};

PassthroughPaymentInfoStage.Editable = PassthroughPaymentInfoEditable;
const ReadOnly = ({ disabled = false }) => {
  const formatMessage = useFormatMessage();
  const { isSubmittingOrder } = React.useContext(CheckoutContext);
  const history = useHistory();
  const dealer = useCurrentApplication() || {};
  const { dealerNetwork } = useNationalSiteContext();
  const nameKey = dealerNetwork ? 'displayName' : 'name';
  return (
    <div className="w-full">
      <div>
        <div className="font-bold mb-2">
          {formatMessage(messages.payInStore)}
        </div>
        <div>{get(dealer, nameKey)}</div>
      </div>
      <div className="flex justify-end w-full">
        <SecondaryButton
          onClick={() => {
            const state = get(history, 'location.state', {});
            history.push('/checkout/payment-info', {
              ...state,
              editing: true
            });
          }}
          size={SecondaryButton.Size.SMALL}
          disabled={disabled || isSubmittingOrder}
        >
          {formatMessage(messages.edit)}
        </SecondaryButton>
      </div>
    </div>
  );
};
PassthroughPaymentInfoStage.ReadOnly = ReadOnly;

PassthroughPaymentInfoStage.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  isVirtualFulfillment: PropTypes.bool
};

export default PassthroughPaymentInfoStage;
export { PassthroughPaymentInfoStage };
