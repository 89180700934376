import { useFormatMessage } from 'app/common/hooks';
import { get, isEmpty, map } from 'lodash';
import { Currency, Icon, Modal, PrimaryButton } from 'app/common/components';
import messages from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/FulfillmentInfoWithOptions.messages';
import React from 'react';
import {
  getDimensionLimit,
  getWeightLimit
} from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/components/deliveryUnitUtil';

const DeliveryValidationError = ({
  deliveryValidation,
  modalOpen,
  setModalOpen,
  modalRef
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Modal
      isOpen={modalOpen}
      ref={modalRef}
      dialogClassName="top-1/4"
      size={Modal.Size.LARGE}
    >
      <Modal.Header>
        <Modal.Header.Title className="flex items-center">
          <Icon
            className="text-yellow-700 mr-2 md:mr-4"
            name="exclamation-triangle"
          />
          <span className="text-yellow-700 font-medium lg:text-xl">
            {formatMessage(messages.deliveryValidationInfoModalTitle)}
          </span>
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body className="text-gray-700 bg-gray-100">
        <div className="items-center mb-4 px-2 py-1 text-sm text-red-700 leading-snug border border-solid border-red-300 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
          <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
          <span>{formatMessage(messages.fulfillmentCriteriaNotMet)}</span>
          <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
            <DoesNotMeetOrderMinimum deliveryValidation={deliveryValidation} />
            <ItemNotEligibleForDelivery
              deliveryValidation={deliveryValidation}
            />
            <NotInDeliveryRadius deliveryValidation={deliveryValidation} />
            <RadiusValidationFailed deliveryValidation={deliveryValidation} />
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center lg:justify-between">
        <PrimaryButton
          onClick={() => {
            setModalOpen(false);
          }}
        >
          {formatMessage(messages.close)}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

const DoesNotMeetOrderMinimum = ({ deliveryValidation }) => {
  const formatMessage = useFormatMessage();

  if (isMeetingOrderMinimum(deliveryValidation)) {
    return null;
  }
  const orderMinimum = get(
    deliveryValidation,
    'deliverySettings.orderMinimumAmount'
  );
  const orderMinimumUnit = get(
    deliveryValidation,
    'deliverySettings.currencyUnit'
  );
  return (
    <li className="mt-1">
      {formatMessage(messages.meetsOrderMinimumAmountMessage)}
      <Currency amount={orderMinimum} currency={orderMinimumUnit} />
    </li>
  );
};

const ItemIneligibleInfo = ({ item, weightLimit, dimensionLimit }) => {
  const formatMessage = useFormatMessage();
  const meetsWeight = get(item, 'meetsWeightCriteria', true);
  const meetsDimension = get(item, 'meetsDimensionCriteria', true);
  const sku = get(item, 'sku');
  return (
    <>
      {!meetsWeight && (
        <li key={sku + 'weight'}>
          <span>{sku} - </span>

          <span>
            {formatMessage(messages.exceedsWeightLimit, { weightLimit })}
          </span>
        </li>
      )}
      {!meetsDimension && (
        <li key={sku + 'dim'}>
          <span>{sku} - </span>
          <span>
            {formatMessage(messages.exceedsDimensionLimit, { dimensionLimit })}
          </span>
        </li>
      )}
    </>
  );
};

const ItemNotEligibleForDelivery = ({ deliveryValidation }) => {
  const items = get(deliveryValidation, 'items', []);
  const formatMessage = useFormatMessage();

  const weightLimit = getWeightLimit(deliveryValidation);
  const dimensionLimit = getDimensionLimit(deliveryValidation);
  const ineligibleItems = items.filter(
    item => !get(item, 'eligibleForDelivery')
  );
  if (isEmpty(ineligibleItems)) {
    return null;
  }
  return (
    <li className="mt-1">
      {formatMessage(messages.itemsEligibleForDelivery)}
      <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
        {map(ineligibleItems, item => {
          return (
            <ItemIneligibleInfo
              item={item}
              weightLimit={weightLimit}
              dimensionLimit={dimensionLimit}
            />
          );
        })}
      </ul>
    </li>
  );
};

const NotInDeliveryRadius = ({ deliveryValidation }) => {
  if (isInDeliveryRadius(deliveryValidation)) {
    return null;
  }
  return <FailureReason message={messages.inDeliveryRadius} />;
};

/**
 * Renders radius validation failure message if unable to determine distance for delivery radius check
 */
const RadiusValidationFailed = ({ deliveryValidation }) => {
  if (!isRadiusValidationFailed(deliveryValidation)) {
    return null;
  }
  return <FailureReason message={messages.deliveryRadiusFailed} />;
};

const FailureReason = ({ message }) => {
  const formatMessage = useFormatMessage();
  return <li className="mt-1">{formatMessage(message)}</li>;
};

function isRadiusValidationFailed(deliveryValidation) {
  return get(
    deliveryValidation,
    'validationRadiusResponse.failedToDetermineDistance',
    false
  );
}

function isInDeliveryRadius(deliveryValidation) {
  const inDeliveryRadius = get(deliveryValidation, 'inDeliveryRadius', true);
  const preferredCustomer = get(
    deliveryValidation,
    'preferredDeliveryCustomer',
    false
  );
  if (preferredCustomer) {
    return true;
  }
  return inDeliveryRadius;
}

function isMeetingOrderMinimum(deliveryValidation) {
  return get(deliveryValidation, 'meetsOrderMinimumAmount', true);
}

export default DeliveryValidationError;
