/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'Checkout.Fulfillment.Cancel',
    defaultMessage: 'Cancel'
  },
  submit: {
    id: 'Checkout.Fulfillment.submit',
    defaultMessage: 'Next'
  },
  orderInstructionsLabel: {
    id: 'Checkout.Fulfillment.orderInstructions',
    defaultMessage: 'Order Instructions'
  },
  orderInstructionsHintQuestion: {
    id: 'Checkout.Fulfillment.orderInstructions.hint.question',
    defaultMessage: 'Can’t find a part?',
    description:
      'This will combined with "Checkout.Fulfillment.orderInstructions.hint" as the preamble'
  },
  orderInstructionsRequirementsHint: {
    id: 'Checkout.Fulfillment.orderInstructions.hint.requirements',
    defaultMessage: 'Must be under 1,000 characters'
  },
  orderInstructionsHint: {
    id: 'Checkout.Fulfillment.orderInstructions.hint',
    defaultMessage:
      '{preamble} Inquire about additional parts and specify pickup/delivery needs in the instruction box above.'
  },
  orderInstructionsAdd: {
    id: 'Checkout.Fulfillment.orderInstructions.add',
    defaultMessage: 'Add Order Instructions'
  },
  orderInstructionsLengthInvalid: {
    id: 'Checkout.Fulfillment.orderInstructions.errors.length',
    defaultMessage: 'Must be under 1000 characters'
  },
  purchaseOrderNumberLengthInvalid: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber.errors.length',
    defaultMessage: 'Must be under 20 characters'
  },
  fulfillmentMethodRequired: {
    id: 'Checkout.Fulfillment.Methods.required',
    defaultMessage: 'Fulfillment method is required'
  },
  generalError: {
    id: 'Checkout.Fulfillment.generalError',
    defaultMessage:
      'There was an error processing your request. Please check your info and try again.'
  },
  chooseDelivery: {
    id: 'Checkout.Fulfillment.chooseDelivery',
    defaultMessage: 'Choose a Delivery Address'
  },
  useDifferentAddress: {
    id: 'Checkout.Fulfillment.useDifferentAddress',
    defaultMessage: 'Use a Different Address'
  },
  legend: {
    id: 'Checkout.Fulfillment.legend',
    defaultMessage: 'Enter Contact Address'
  },
  caveat: {
    id: 'Checkout.Fulfillment.caveat',
    defaultMessage:
      '{dealerName} will use the order info to help differentiate customers and contact you to determine payment and pickup/delivery details.'
  },
  deliveryUnavailable: {
    id: 'Checkout.Fulfillment.deliveryUnavailable',
    defaultMessage: 'Delivery not available:\n'
  },
  pickupSelect: {
    id: 'Checkout.Fulfillment.pickup',
    defaultMessage: 'Pickup'
  },
  deliverySelect: {
    id: 'Checkout.Fulfillment.deliverySelect',
    defaultMessage: 'Delivery'
  },
  dealerAllowsDelivery: {
    id: 'Checkout.Fulfillment.Deny.dealerAllowsDelivery',
    defaultMessage:
      'Delivery is not available at this location. Please call the location at {phoneNumber} for alternative options.'
  },
  meetsOrderMinimumAmount: {
    id: 'Checkout.Fulfillment.Deny.meetsOrderMinimumAmount',
    defaultMessage:
      'Order does not meet the minimum order amount, your total needs to be above {orderMinimum} {orderMinimumUnit}'
  },
  itemsEligibleForDelivery: {
    id: 'Checkout.Fulfillment.Deny.itemsEligibleForDelivery',
    defaultMessage: 'One or more items are not eligible for delivery'
  },
  inDeliveryRadius: {
    id: 'Checkout.Fulfillment.Deny.inDeliveryRadius',
    defaultMessage: 'Provided address is outside of delivery radius'
  },
  deliveryRadiusFailed: {
    id: 'Checkout.Fulfillment.Deny.deliveryRadiusFailed',
    defaultMessage: 'Unable to determine distance from delivery radius'
  },
  modalTitle: {
    id: 'Checkout.Fulfillment.modalTitle',
    defaultMessage: 'Provided address is not available for delivery'
  },
  modifyPickup: {
    id: 'Checkout.Fulfillment.modifyPickup',
    defaultMessage: 'Set to pickup'
  },
  deliveryLegend: {
    id: 'Checkout.Fulfillment.deliveryLegend',
    defaultMessage: 'Delivery Method'
  },
  deliveryRadiusUnavailable: {
    id: 'Checkout.deliveryRadiusUnavailable',
    defaultMessage:
      'Your provided address is unavailable for delivery{breakLine}' +
      'You can:{breakLineWide}' +
      '1) Pickup your order and continue checking out{breakLine}' +
      '2) Go back and change your address{breakLine}' +
      '{breakLine}'
  },
  purchaseOrderNumberLabel: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber',
    defaultMessage: 'PO Number'
  },
  purchaseOrderNumberAdd: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber.Add',
    defaultMessage: 'Add PO Number'
  },
  purchaseOrderNumberHint: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber.Hint',
    defaultMessage: 'PO numbers cannot be longer than 20 characters'
  }
});
