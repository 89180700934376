/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';
import { Redirect } from 'react-router-dom';

import { CartContext } from 'app/common/contexts';
import { useNationalSiteContext } from 'app/common/hooks';

import { GuestForm, SignInPrompt } from './components';
import { AuthContext } from 'app/auth/contexts';
import { CheckoutContext } from 'app/checkout/contexts';

/**
 * Render component for users to sign in or select guest checkout.
 */
const AccountInfo = () => {
  const { isAuthenticated } = React.useContext(AuthContext);
  const { cart, resolving } = React.useContext(CartContext);
  const { isGuest } = React.useContext(CheckoutContext);
  const { address = {}, fulfillmentOption = {} } = get(
    cart,
    'fulfillmentGroups[0]',
    {}
  );
  const resolved = !resolving && cart !== undefined;
  const { dealerSelected } = useNationalSiteContext();

  if (resolved && (isAuthenticated || isGuest)) {
    if (!dealerSelected) {
      return <Redirect to="/checkout/location-info" />;
    }

    if (isEmpty(address) || isEmpty(fulfillmentOption)) {
      return <Redirect to="/checkout/fulfillment-info" />;
    }

    return <Redirect to="/checkout/review" />;
  }

  return (
    <section className="flex flex-col items-start justify-between w-full mb-8 lg:flex-row">
      <AccountInfo.GuestForm />
      <div className="my-2 lg:mx-2 lg:my-0" />
      <AccountInfo.SignInPrompt />
    </section>
  );
};

AccountInfo.GuestForm = GuestForm;
AccountInfo.SignInPrompt = SignInPrompt;

export default AccountInfo;
export { AccountInfo };
