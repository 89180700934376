/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { get, head, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  Icon,
  LoadingIcon,
  PrimaryButton,
  SecondaryButton
} from 'app/common/components';
import {
  useCartInfo,
  useCartPaymentApi,
  useFormatMessage,
  useHistory,
  useModifyCartRequest
} from 'app/common/hooks';
import { CartContext } from 'app/common/contexts';

import messages from './PassthroughPaymentInfoEditable.messages';
import { Environment } from 'app/common/services';
import goToNextCheckoutStep, {
  REVIEW_PATH
} from 'app/checkout/components/CheckoutLayout/components/hooks/useGoToNextStep';

/**
 * Render component for the editable form fields for the checkout's payment
 * stage.
 *
 * @visibleName Checkout: Payment Info Form
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const PassthroughPaymentInfoEditable = ({
  active = false,
  completed,
  isPayInStore
}) => {
  const formatMessage = useFormatMessage();
  const { cart, setCart } = React.useContext(CartContext);
  const goToNextStep = useGoToReviewStep();
  const {
    checkoutOperations: { baseUrl }
  } = useCartInfo();
  const {
    error,
    exception,
    sendCallback: updateCartInfo
  } = useModifyCartRequest(`${baseUrl}/${cart.id}`, null, false);
  const isPayInStoreSet = get(cart, 'attributes.PAY_IN_STORE', false);
  const activePayments = head(get(cart, 'activePayments', []));
  const { sendCallback: sendPaymentRequest } =
    useCartPaymentApi(activePayments);

  return (
    <Formik
      initialValues={{ attributes: { PAY_IN_STORE: true }, activePayments: [] }}
      onSubmit={async (values, actions) => {
        const { attributes } = values;
        actions.setSubmitting(true);
        const cartVersionHeaderName = Environment.get(
          'cart.version.header',
          'X-Cart-Version'
        );
        // To be utilized with upgrade so the transfer to a PaymentTransaction can be slotted in.
        const updatedPayment = await sendPaymentRequest({ method: 'del' });
        setCart(updatedPayment);

        const updatedCart = await updateCartInfo({
          method: 'patch',
          data: {
            attributes
          },
          headers: {
            [cartVersionHeaderName]: cart.version
          }
        });
        actions.setSubmitting(false);

        if (!isEmpty(updatedCart)) {
          setCart(updatedCart);

          if (error) {
            return;
          }

          goToNextStep();
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            {error && !isSubmitting && (
              <div className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
                <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
                <span>
                  {exception.exception || formatMessage(messages.genericError)}
                </span>
              </div>
            )}
            <div className="flex justify-end mt-4">
              {completed && isPayInStoreSet && (
                <SecondaryButton
                  className="mr-2"
                  disabled={isSubmitting || !active}
                  onClick={goToNextStep}
                >
                  {formatMessage(messages.cancel)}
                </SecondaryButton>
              )}
              <PrimaryButton
                className="relative"
                type="submit"
                disabled={isSubmitting || !active || !isPayInStore}
              >
                <div
                  className={classNames({ 'text-transparent': isSubmitting })}
                >
                  {formatMessage(messages.submit)}
                </div>
                {isSubmitting && (
                  <div className="absolute inset-0 flex justify-center items-center">
                    <LoadingIcon className="text-blue-500" />
                  </div>
                )}
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

function useGoToReviewStep() {
  const history = useHistory();
  return goToNextCheckoutStep(history, REVIEW_PATH, {
    paymentComplete: true,
    editing: false
  });
}

PassthroughPaymentInfoEditable.GatewayType = 'PASSTHROUGH';

PassthroughPaymentInfoEditable.propTypes = {
  /**
   * Whether the payment info stage is active. Affects whether the form is
   * disabled.
   */
  active: PropTypes.bool
};

export default PassthroughPaymentInfoEditable;
export { PassthroughPaymentInfoEditable };
