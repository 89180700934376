/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import useSearchRequestConfig from '../useSearchRequestConfig';

/**
 * Hook that adds the category filter for browse requests.
 *
 * @param {string} categoryId - The ID of the category being browsed
 * @param {string} searchString - URL query string
 * @param {number} [size] - Size of the results
 * @param {Array} [includeFacets] - Facets to include in the response
 *
 * @return {{paramsSerializer: (function(*=): string), params: ({}&{size, page: number, sort, type: string})}}
 */
function useBrowseRequestConfig(categoryId, searchString, size, includeFacets) {
  const defaultFilters = useMemo(() => {
    if (isEmpty(categoryId)) {
      return {};
    }
    return [{ name: 'categoryIds', values: categoryId }];
  }, [categoryId]);

  return useSearchRequestConfig(
    searchString,
    defaultFilters,
    size,
    'popularity,asc',
    includeFacets
  );
}

export default useBrowseRequestConfig;
