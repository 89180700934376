/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useCartInfo, useRestApi } from 'app/common/hooks';
import { Environment } from 'app/common/services';

/**
 * Hook to use to interact with the fulfillment methods API.
 *
 * @param {Object} cart - Cart being fulfilled
 * @param {string} referenceNumber - ID of the fulfillment group
 * @param {boolean}  [sendImmediate=true] - Whether to send the request
 *     immediately upon using the hook. If false, then the caller is responsible
 *     for triggering the `sendCallback`. Defaults to `true`.
 *
 * @deprecated Use `useFulfillmentOptions` instead.
 * @return {{
 *   error: boolean,
 *   exception: Object,
 *   loading: boolean,
 *   response: Object,
 *   sendCallback: Function
 * }}
 */
function useFulfillmentMethods(cart, referenceNumber, sendImmediate = true) {
  const { baseUrl, fulfillmentOptionsContextPath } =
    useCartInfo().checkoutOperations;
  const fulfillmentServiceEnabled = isFulfillmentServiceEnabled();

  return useRestApi(
    `${baseUrl}/${cart.id}${fulfillmentOptionsContextPath}/${referenceNumber}`,
    null,
    sendImmediate && !fulfillmentServiceEnabled
  );
}

function isFulfillmentServiceEnabled() {
  return Environment.get('FULFILLMENT_SERVICE_ENABLED', 'true') === 'true';
}

export default useFulfillmentMethods;
