/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  deliveryAddressLabel: {
    id: 'Checkout.Fulfillment.delivery.address.label.readOnly',
    defaultMessage: 'Contact at'
  },
  deliveryMethodLabel: {
    id: 'Checkout.Fulfillment.delivery.method.label.readOnly',
    defaultMessage: 'Deliver using'
  },
  deliveryMethodName: {
    id: 'Checkout.Fulfillment.delivery.method.name.readOnly',
    defaultMessage: '{name} Delivery'
  },
  deliveryMethodEta: {
    id: 'Checkout.Fulfillment.delivery.method.eta.readOnly',
    defaultMessage: 'Arriving by {date}'
  },
  pickupContactLabel: {
    id: 'Checkout.Fulfillment.pickup.contact.label.readOnly',
    defaultMessage: 'Pickup contact'
  },
  pickup: {
    id: 'Checkout.Fulfillment.pickup.location.label.readOnly',
    defaultMessage: 'Pickup from'
  },
  edit: {
    id: 'Checkout.Fulfillment.edit',
    defaultMessage: 'Edit'
  },
  primaryPhone: {
    id: 'Checkout.Fulfillment.delivery.method.primaryPhone.label.readOnly',
    defaultMessage: 'Primary ({kind}): {phone}'
  },
  secondaryPhone: {
    id: 'Checkout.Fulfillment.delivery.method.secondaryPhone.label.readOnly',
    defaultMessage: 'Secondary ({kind}): {phone}'
  },
  fax: {
    id: 'Checkout.Fulfillment.delivery.method.fax.label.readOnly',
    defaultMessage: 'Fax: {phone}'
  },
  orderInstructions: {
    id: 'Checkout.Fulfillment.delivery.method.orderInstructions',
    defaultMessage: 'Order Instructions'
  },
  communicationPreference: {
    id: 'Checkout.Fulfillment.delivery.method.communicationPreference',
    defaultMessage: 'Prefer to contact by'
  },
  caveat: {
    id: 'Checkout.Fulfillment.caveat.readOnly',
    defaultMessage:
      '{dealerName} will use the order info to help differentiate customers and contact you to determine payment and pickup/delivery details.'
  },
  deliveryMethod: {
    id: 'Checkout.Fulfillment.deliveryMethod',
    defaultMessage: 'Delivery Method'
  },
  deliveryMethodDeliver: {
    id: 'Checkout.Fulfillment.deliveryMethod.deliver',
    defaultMessage: 'Deliver'
  },
  deliveryMethodPickup: {
    id: 'Checkout.Fulfillment.deliveryMethod.pickup',
    defaultMessage: 'Pickup'
  },
  purchaseOrderNumber: {
    id: 'Checkout.Fulfillment.purchaseOrderNumber',
    defaultMessage: 'PO Number'
  },
  paymentMethod: {
    id: 'Checkout.Fulfillment.paymentMethod',
    defaultMessage: 'Payment Method'
  }
});
