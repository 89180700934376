/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext } from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Stage } from 'app/checkout/elements';
import { FulfillmentType } from 'app/common/constants';
import { CartContext } from 'app/common/contexts';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';
import messages from './FulfillmentInfoWithOptions.messages';
import { FulfillmentInfoWithOptionsReadOnly } from './FulfillmentInfoWithOptionsReadOnly';
import { FulfillmentInfoWithOptionsEditable } from './FulfillmentInfoWithOptionsEditable';

/**
 * Render component for the fulfillment info stage of checkout, including
 * getting the delivery address and delivery method.
 */
const FulfillmentInfoWithOptions = ({
  active,
  stageNumber = 2,
  chooseDelivery = false,
  nextStepPath
}) => {
  const formatMessage = useFormatMessage();
  const { cart, resolving } = useContext(CartContext);
  const resolved = !resolving && cart !== undefined;
  const { fulfillmentGroup, completed } = isStepCompleted(cart);
  const { dealerSelected } = useNationalSiteContext();

  if (active && resolved) {
    if (!dealerSelected) {
      return <Redirect to="/checkout/location-info" push />;
    }

    if (fulfillmentGroup.type === FulfillmentType.VIRTUAL) {
      return <Redirect to="/checkout/payment-info" push />;
    }
  }

  return (
    <Stage
      active={active}
      completed={completed}
      heading={formatMessage(messages.title)}
      number={stageNumber}
    >
      {active || !completed ? (
        <FulfillmentInfoWithOptionsEditable
          active={active}
          completed={completed}
          nextStepPath={nextStepPath}
        />
      ) : (
        <FulfillmentInfoWithOptionsReadOnly chooseDelivery={chooseDelivery} />
      )}
    </Stage>
  );
};

function isStepCompleted(cart) {
  const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
  const address = fulfillmentGroup.address || {};
  const completed = !isEmpty(address);
  return { fulfillmentGroup, completed };
}

FulfillmentInfoWithOptions.propTypes = {
  active: PropTypes.bool
};

export default FulfillmentInfoWithOptions;
export { FulfillmentInfoWithOptions };
