/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import {
  delay,
  get,
  isEmpty,
  isNull,
  isString,
  join,
  map,
  mapValues,
  omitBy,
  set,
  size,
  toPairs
} from 'lodash';
import PropTypes from 'prop-types';
import TagManager from 'app/common/tagmanager';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { CheckoutContext } from 'app/checkout/contexts';
import { useFulfillmentMethods } from 'app/checkout/hooks';
import {
  determineDefaultCountry,
  determineDefaultStateProvinceRegion
} from 'app/checkout/utils/AddressUtils';
import {
  AddressForm,
  AuthenticatedAddressForm,
  CommunicationPreferences,
  DecoratedField,
  Icon,
  LoadingIcon,
  Modal,
  OptionalField,
  PrimaryButton,
  SecondaryButton,
  SelectedButton,
  TextAreaField
} from 'app/common/components';
import {
  CommunicationPreferenceType,
  FulfillmentType,
  IsoCountries
} from 'app/common/constants';
import { CartContext, LocaleContext } from 'app/common/contexts';
import {
  useCartDeliveryValidation,
  useCurrentApplication,
  useFormatMessage,
  useFormatNumber,
  useFulfillmentInfoApi,
  useToggle
} from 'app/common/hooks';
import { normalizePhoneNumber } from 'app/common/utils/AddressUtils';

import { useFulfillmentInfoValidationSchema } from './hooks';

import messages from './FulfillmentInfoEditable.messages';

/**
 * Render component for the fulfillment info stage of checkout, including
 * getting the delivery address and delivery method.
 */
const FulfillmentInfoEditable = ({ active, completed, history }) => {
  const formatMessage = useFormatMessage();
  const validationSchema = useFulfillmentInfoValidationSchema(messages);
  const { currentLocale } = useContext(LocaleContext);
  const countryDefault = determineDefaultCountry(currentLocale);
  const stateProvinceRegionDefault =
    determineDefaultStateProvinceRegion(countryDefault);
  const { cart, setCart } = useContext(CartContext);
  const orderInstructions = get(cart, 'attributes[ORDER_INSTRUCTIONS]');
  const communicationPreference = get(cart, 'attributes[COMMUNICATION_PREF]');
  const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
  const emptyAddress = useMemo(() => ({}), []);
  const address = fulfillmentGroup.address || emptyAddress;
  const [fulfillmentType, setFulfillmentType] = useState(
    fulfillmentGroup.type || FulfillmentType.SHIP
  );
  const referenceNumber = fulfillmentGroup.referenceNumber;
  const { isAuthenticated } = useContext(AuthContext);
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const dealer = useCurrentApplication() || {};
  const dealerName = get(dealer, 'displayName');
  const dealerPhoneNumber = get(dealer, 'phoneNumber');
  const { error, sendCallback: updateFulfillmentGroup } = useFulfillmentInfoApi(
    cart.id,
    referenceNumber
  );
  const items = get(cart, 'cartItems', []);
  const gtmPushed = React.useRef(false);
  const formatNumber = useFormatNumber();
  const [showAddressErrors, setShowAddressErrors] = React.useState(false);
  const [formAddress, setFormAddress] = React.useState(address);
  const {
    sendCallback: refetchFulfillmentMethods,
    ...fulfillmentMethodsState
  } = useFulfillmentMethods(cart, referenceNumber);
  const [, setFulfillmentMethods] = useState(undefined);
  const { promoCodeUpdated, setPromoCodeUpdated } = useContext(CheckoutContext);
  const { loading: deliveryValidationLoading, sendCallback: deliveryCallback } =
    useCartDeliveryValidation(undefined);
  const [deliveryValidation, setDeliveryValidation] = useState(undefined);
  const [checkUpdatedDeliveryValidation, setCheckUpdatedDeliveryValidation] =
    useToggle(false);
  const [showModal, toggleModal] = useToggle(false);
  const goToNextStep = useGoToNextStep();
  const purchaseOrderNumber = get(cart, 'purchaseOrderNumber');

  React.useEffect(() => {
    (async () => setDeliveryValidation(await deliveryCallback()))();
  }, [deliveryCallback, cart]);

  React.useEffect(() => {
    // Determine if we need to update the validation of delivery if we are still trying to use delivery.
    if (
      checkUpdatedDeliveryValidation &&
      fulfillmentType === FulfillmentType.SHIP
    ) {
      if (isInDeliveryRadius(deliveryValidation)) {
        setCheckUpdatedDeliveryValidation(false);
        goToNextStep();
      } else if (isNotInDeliveryRadius(deliveryValidation)) {
        setCheckUpdatedDeliveryValidation(false);
        toggleModal(true);
      }
      // This shouldn't happen normally, but if it does then fallback to pickup.
      else if (isRadiusValidationFailed(deliveryValidation)) {
        setCheckUpdatedDeliveryValidation(false);
        setFulfillmentType(FulfillmentType.PICKUP);
      }
    }
  }, [
    deliveryValidation,
    checkUpdatedDeliveryValidation,
    setCheckUpdatedDeliveryValidation,
    fulfillmentType,
    toggleModal,
    goToNextStep
  ]);

  React.useEffect(() => {
    setFulfillmentMethods(get(fulfillmentMethodsState, 'response'));
  }, [fulfillmentMethodsState]);

  React.useEffect(() => {
    if (promoCodeUpdated) {
      setPromoCodeUpdated(false);
      (async () => {
        const response = await refetchFulfillmentMethods();
        setFulfillmentMethods(get(response, 'response'));
      })();
    }
  }, [
    promoCodeUpdated,
    setPromoCodeUpdated,
    setFulfillmentMethods,
    refetchFulfillmentMethods
  ]);

  React.useEffect(() => {
    setFormAddress(selectedAddress || address);
  }, [address, selectedAddress]);

  const [dataLayer, setDataLayer] = React.useState(null);

  React.useEffect(() => {
    if (gtmPushed.current || !active) {
      return;
    }

    setDataLayer(
      TagManager.dataLayer({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1
            },
            products: items.map((item, i) => {
              const {
                quantity,
                sku,
                unitPrice,
                attributes: { categoryNames, productDescription }
              } = item;
              const brand = get(item, 'attributes.productBrand.value');

              return {
                brand,
                category: join(categoryNames, ', '),
                id: sku,
                name: productDescription,
                position: i,
                price: get(unitPrice, 'amount', 0.0),
                quantity
              };
            })
          }
        }
      })
    );
    gtmPushed.current = true;
  }, [active, items, setDataLayer]);

  function useGoToNextStep() {
    return React.useCallback(() => {
      const state = get(history, 'location.state', {});
      const nextState = { ...state, editing: false };
      const nextUri = '/checkout/review';

      delay(() => {
        history.push(nextUri, nextState);
      }, 300);
    }, []);
  }

  return (
    <>
      {dataLayer}
      <Formik
        initialValues={{
          fulfillmentType: fulfillmentType,
          fullName: formAddress.fullName || '',
          firstName: formAddress.firstName || '',
          lastName: formAddress.lastName || '',
          addressLine1: formAddress.addressLine1 || '',
          addressLine2: formAddress.addressLine2 || '',
          addressLine3: formAddress.addressLine3 || '',
          country: get(formAddress, 'country') || countryDefault,
          stateProvinceRegion:
            formAddress.stateProvinceRegion || stateProvinceRegionDefault,
          city: formAddress.city || '',
          companyName: formAddress.companyName || '',
          orderInstructions: orderInstructions || '',
          postalCode: formAddress.postalCode || '',
          phonePrimary: get(formAddress, 'phonePrimary.phoneNumber') || '',
          phonePrimaryType: get(formAddress, 'phonePrimary.type') || 'MOBILE',
          phoneSecondary: get(formAddress, 'phoneSecondary.phoneNumber') || '',
          phoneSecondaryType:
            get(formAddress, 'phoneSecondary.type') || 'MOBILE',
          phoneFax: get(formAddress, 'phoneFax.phoneNumber') || '',
          phoneFaxType: get(formAddress, 'phoneFax.type') || 'FAX',
          preferredCommunicationMethod:
            communicationPreference || CommunicationPreferenceType.Email.value,
          purchaseOrderNumber: purchaseOrderNumber || ''
        }}
        onSubmit={async (values, actions) => {
          setShowAddressErrors(false);
          if (active) {
            FulfillmentInfoEditable.handleSubmit({
              values,
              updateFulfillmentGroup
            })
              .then(data => {
                if (!isEmpty(data)) {
                  setCart(data);

                  if (fulfillmentType === FulfillmentType.PICKUP) {
                    goToNextStep();
                  } else {
                    setCheckUpdatedDeliveryValidation(true);
                  }
                }
              })
              .catch(err => {
                const fieldErrors = err.fieldErrors;
                if (!isEmpty(fieldErrors)) {
                  const errorPairs = toPairs(
                    mapValues(fieldErrors, errorArray => errorArray[0].reason)
                  );
                  const errors = errorPairs.reduce((acc, [key, value]) => {
                    set(
                      acc,
                      key
                        .replace('address.', '')
                        .replace('phonePrimary.phoneNumber', 'phonePrimary')
                        .replace('phoneSecondary.phoneNumber', 'phoneSecondary')
                        .replace('phoneFax.phoneNumber', 'phoneFax')
                        .replace(
                          'attributes[COMMUNICATION_PREF]',
                          'preferredCommunicationMethod'
                        ),
                      value
                    );
                    return acc;
                  }, {});
                  actions.setErrors(errors);
                  setShowAddressErrors(true);
                }
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ isSubmitting, values }) => (
          <Form className="flex flex-col">
            <CheckoutDeliveryValidationModal
              setFulfillmentType={setFulfillmentType}
              open={showModal}
              toggleOpen={toggleModal}
              goToNextStep={goToNextStep}
              setCart={setCart}
              values={values}
              updateFulfillmentGroup={updateFulfillmentGroup}
            />
            {error && !isSubmitting && (
              <aside className="flex items-center mb-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
                <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
                <span>{formatMessage(messages.generalError)}</span>
              </aside>
            )}
            <aside className="flex items-center mb-4 px-2 py-1 text-sm text-blue-700 leading-snug border border-solid border-blue-300 bg-blue-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
              <Icon className="mr-2 md:mr-4" name="info-circle" />
              {/*Div is needed to prevent bolded text from getting flexed as a row*/}
              <div>
                <FormattedMessage
                  {...messages.caveat}
                  values={{ dealerName: <b>{dealerName}</b> }}
                />
              </div>
            </aside>
            <CheckoutDeliveryValidationError
              deliveryValidationLoading={deliveryValidationLoading}
              deliveryValidation={deliveryValidation}
              dealerPhoneNumber={dealerPhoneNumber}
            />
            <DeliveryMethodPicker
              fulfillmentType={fulfillmentType}
              setFulfillmentType={setFulfillmentType}
              deliveryValidation={deliveryValidation}
              deliveryValidationLoading={deliveryValidationLoading}
            />
            {isAuthenticated && (
              <AuthenticatedAddressForm
                type={AddressForm.Type.FULFILLMENT}
                address={address}
                isSubmitting={isSubmitting}
                disabled={isSubmitting || !active}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                showAddressErrors={showAddressErrors}
              />
            )}
            {!isAuthenticated && (
              <AddressForm
                type={AddressForm.Type.FULFILLMENT}
                disabled={isSubmitting || !active}
              />
            )}
            <hr className="-mt-3 mb-4 bg-gray-400 lg:-mt-2 lg:mb-6" />
            <div className="flex flex-col" style={{ paddingBottom: 15 }}>
              <fieldset
                className="flex flex-col items-start justify-start"
                disabled={isSubmitting || !active}
              >
                <legend className="flex mb-4 text-gray-900 font-bold lg:text-lg">
                  <span>
                    {formatMessage(messages.purchaseOrderNumberLabel)}
                  </span>
                </legend>
                <OptionalField
                  name="purchaseOrderNumber"
                  triggerLabel={formatMessage(messages.purchaseOrderNumberAdd)}
                >
                  <DecoratedField
                    disabled={isSubmitting || !active}
                    id="purchaseOrderNumber"
                    name="purchaseOrderNumber"
                    placeholder={formatMessage(
                      messages.purchaseOrderNumberLabel
                    )}
                    maxLength={20}
                    hint={formatMessage(messages.purchaseOrderNumberHint)}
                  />
                </OptionalField>
              </fieldset>
            </div>
            <hr className="-mt-3 mb-4 bg-gray-400 lg:-mt-2 lg:mb-6" />
            <CommunicationPreferences disabled={isSubmitting || !active} />
            <hr className="-mt-3 mb-4 bg-gray-400 lg:mt-0 lg:mb-6" />
            <fieldset
              className="flex flex-col items-start justify-start"
              disabled={isSubmitting || !active}
            >
              <legend className="mb-4 text-gray-900 font-bold lg:text-lg">
                {formatMessage(messages.orderInstructionsLabel)}
              </legend>
              <OptionalField
                name="orderInstructions"
                triggerLabel={formatMessage(messages.orderInstructionsAdd)}
              >
                <DecoratedField
                  containerWidths="w-full"
                  id="order-instructions"
                  name="orderInstructions"
                  component={TextAreaField}
                  label={formatMessage(messages.orderInstructionsLabel)}
                  labelProps={{ className: 'sr-only' }}
                  renderHint={() => (
                    <div className="flex items-start justify-between p-2 leading-snug">
                      <em className="block flex-grow text-xs text-gray-600 not-italic sm:text-sm">
                        <FormattedMessage
                          {...messages.orderInstructionsHint}
                          values={{
                            preamble: (
                              <b>
                                {formatMessage(
                                  messages.orderInstructionsHintQuestion
                                )}
                              </b>
                            )
                          }}
                        />{' '}
                        <span className="italic">
                          {formatMessage(
                            messages.orderInstructionsRequirementsHint
                          )}
                        </span>
                      </em>
                      <div
                        className={classNames(
                          'flex flex-no-wrap items-center justify-end pl-1 text-xs font-bold sm:text-sm',
                          {
                            'text-green-700':
                              size(get(values, 'orderInstructions')) < 1000,
                            'text-yellow-600':
                              size(get(values, 'orderInstructions')) === 1000,
                            'text-red-700':
                              size(get(values, 'orderInstructions')) > 1000
                          }
                        )}
                      >
                        <div>
                          {formatNumber(size(get(values, 'orderInstructions')))}
                        </div>
                        <div className="mx-1">{'/'}</div>
                        <div>{formatNumber(1000)}</div>
                      </div>
                    </div>
                  )}
                  disabled={isSubmitting || !active}
                  autoComplete="off"
                  widths="w-full h-24 lg:h-28"
                />
              </OptionalField>
            </fieldset>
            {isSubmitting && (
              <div className="absolute inset-0 flex justify-center items-center">
                <LoadingIcon className="text-blue-500" />
              </div>
            )}
            <div className="flex justify-end mt-4">
              {completed && (
                <SecondaryButton
                  className="mr-2"
                  disabled={isSubmitting || !active}
                  onClick={goToNextStep}
                >
                  {formatMessage(messages.cancel)}
                </SecondaryButton>
              )}
              <PrimaryButton type="submit" disabled={isSubmitting || !active}>
                {formatMessage(messages.submit)}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

function isDeliveryAllowed(deliveryValidation) {
  return (
    get(deliveryValidation, 'preferredDeliveryCustomer') ||
    get(deliveryValidation, 'dealerAllowsDelivery')
  );
}

function isMeetingOrderMinimum(deliveryValidation) {
  return (
    get(deliveryValidation, 'meetsOrderMinimumAmount') ||
    isNull(get(deliveryValidation, 'meetsOrderMinimumAmount'))
  );
}

// Check to see if we're in the delivery radius or override if the user is a preferred customer.
function isInDeliveryRadius(deliveryValidation) {
  const inDeliveryRadius = get(deliveryValidation, 'inDeliveryRadius');
  return (
    (!isNull(inDeliveryRadius) && inDeliveryRadius) ||
    get(deliveryValidation, 'preferredDeliveryCustomer')
  );
}

function isNotInDeliveryRadius(deliveryValidation) {
  const inDeliveryRadius = get(deliveryValidation, 'inDeliveryRadius');
  return !isNull(inDeliveryRadius) && !inDeliveryRadius;
}

function isRadiusValidationFailed(deliveryValidation) {
  return (
    !get(deliveryValidation, 'preferredDeliveryCustomer') &&
    isNull(get(deliveryValidation, 'inDeliveryRadius')) &&
    get(
      get(deliveryValidation, 'validationRadiusResponse'),
      'failedToDetermineDistance'
    )
  );
}

function isDeliverable(deliveryValidation) {
  return (
    isDeliveryAllowed(deliveryValidation) &&
    isMeetingOrderMinimum(deliveryValidation) &&
    get(deliveryValidation, 'itemsEligibleForDelivery')
  );
}

FulfillmentInfoEditable.handleSubmit = ({ values, updateFulfillmentGroup }) => {
  const {
    addressLine1,
    addressLine2,
    addressLine3,
    city,
    preferredCommunicationMethod,
    companyName,
    fulfillmentType,
    fullName,
    firstName,
    lastName,
    country,
    stateProvinceRegion,
    orderInstructions,
    postalCode,
    phonePrimary: phonePrimaryNumber,
    phonePrimaryType,
    phoneSecondary: phoneSecondaryNumber,
    phoneSecondaryType,
    phoneFax: phoneFaxNumber,
    phoneFaxType,
    purchaseOrderNumber
  } = values;
  const isoCountry = IsoCountries[country];

  return updateFulfillmentGroup({
    method: 'patch',
    data: {
      type: fulfillmentType,
      fulfillmentOption: { name: 'DEALER' },
      attributes: {
        COMMUNICATION_PREF: preferredCommunicationMethod,
        ORDER_INSTRUCTIONS: orderInstructions
      },
      address: omitBy(
        {
          fullName,
          firstName,
          lastName,
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          companyName,
          country: isoCountry.alpha2,
          stateProvinceRegion,
          postalCode,
          phonePrimary: {
            phoneNumber: normalizePhoneNumber(phonePrimaryNumber),
            type: phonePrimaryType
          },
          phoneSecondary: isEmpty(phoneSecondaryNumber)
            ? undefined
            : {
                phoneNumber: normalizePhoneNumber(phoneSecondaryNumber),
                type: phoneSecondaryType
              },
          phoneFax: isEmpty(phoneFaxNumber)
            ? undefined
            : {
                phoneNumber: normalizePhoneNumber(phoneFaxNumber),
                type: phoneFaxType
              }
        },
        isEmpty
      ),
      purchaseOrderNumber: purchaseOrderNumber
    }
  });
};

const DeliveryMethodPicker = ({
  fulfillmentType,
  setFulfillmentType,
  deliveryValidation,
  deliveryValidationLoading
}) => {
  const formatMessage = useFormatMessage();
  // Set the fulfillment type to pickup if it fails to be deliverable
  if (
    !isDeliverable(deliveryValidation) &&
    deliveryValidation !== undefined &&
    !deliveryValidationLoading
  ) {
    setFulfillmentType(FulfillmentType.PICKUP);
  }
  return (
    <>
      <h3 className="mb-4 text-gray-900 font-bold lg:text-lg">
        {formatMessage(messages.deliveryLegend)}
      </h3>
      <div className="pb-4 flex items-center">
        {fulfillmentType === FulfillmentType.SHIP ? (
          <>
            <SelectedButton>
              {formatMessage(messages.deliverySelect)}
            </SelectedButton>
            <SecondaryButton
              className="ml-4"
              onClick={() => {
                setFulfillmentType(FulfillmentType.PICKUP);
              }}
            >
              {formatMessage(messages.pickupSelect)}
            </SecondaryButton>
          </>
        ) : (
          <>
            <SecondaryButton
              disabled={!isDeliverable(deliveryValidation)}
              onClick={() => {
                setFulfillmentType(FulfillmentType.SHIP);
              }}
            >
              {formatMessage(messages.deliverySelect)}
            </SecondaryButton>
            <SelectedButton className="ml-4">
              {formatMessage(messages.pickupSelect)}
            </SelectedButton>
          </>
        )}
      </div>
    </>
  );
};

const CheckoutDeliveryValidationError = ({
  deliveryValidationLoading,
  deliveryValidation,
  dealerPhoneNumber
}) => {
  const formatMessage = useFormatMessage();
  const deliveryAllowed = isDeliveryAllowed(deliveryValidation);
  return (
    !(
      !isRadiusValidationFailed(deliveryValidation) &&
      isDeliverable(deliveryValidation) &&
      (get(deliveryValidation, 'inDeliveryRadius') ||
        isNull(get(deliveryValidation, 'inDeliveryRadius')))
    ) &&
    !deliveryValidationLoading && (
      <div className="items-center mb-4 px-2 py-1 text-sm text-red-700 leading-snug border border-solid border-red-300 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
        <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
        {!deliveryAllowed
          ? formatMessage(messages.dealerAllowsDelivery, {
              phoneNumber: dealerPhoneNumber
            })
          : formatMessage(messages.deliveryUnavailable)}
        {deliveryAllowed && (
          <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
            {
              <>
                {!isMeetingOrderMinimum(deliveryValidation) && (
                  <li className="mt-1">
                    {formatMessage(messages.meetsOrderMinimumAmount, {
                      orderMinimum: get(
                        deliveryValidation,
                        'deliverySettings.orderMinimumAmount'
                      ),
                      orderMinimumUnit: get(
                        deliveryValidation,
                        'deliverySettings.currencyUnit'
                      )
                    })}
                  </li>
                )}
                {!get(deliveryValidation, 'itemsEligibleForDelivery') && (
                  <li className="mt-1">
                    {formatMessage(messages.itemsEligibleForDelivery)}
                    <ul className="flex flex-col pl-6 mt-1 leading-normal list-disc">
                      {map(get(deliveryValidation, 'items'), item => {
                        const sku = get(item, 'sku');
                        return (
                          !get(item, 'eligibleForDelivery') && (
                            <li key={sku} className="mt-1">
                              {sku}
                            </li>
                          )
                        );
                      })}
                    </ul>
                  </li>
                )}
                {isNotInDeliveryRadius(deliveryValidation) && (
                  <li className="mt-1">
                    {formatMessage(messages.inDeliveryRadius)}
                  </li>
                )}
                {isRadiusValidationFailed(deliveryValidation) && (
                  <li className="mt-1">
                    {formatMessage(messages.deliveryRadiusFailed)}
                  </li>
                )}
              </>
            }
          </ul>
        )}
      </div>
    )
  );
};

const CheckoutDeliveryValidationModal = ({
  open = false,
  toggleOpen,
  setFulfillmentType,
  goToNextStep,
  setCart,
  values,
  updateFulfillmentGroup
}) => {
  const modalRef = React.useRef(null);
  const formatMessage = useFormatMessage();
  const [submitting, setSubmitting] = React.useState(false);

  // Update the fulfillment to pickup delivery immediately after a validation failure.
  // This prevents the fulfillment from being sent erroneously as delivery while no address is set yet.
  React.useEffect(() => {
    if (
      open &&
      values.fulfillmentType !== FulfillmentType.PICKUP &&
      !submitting
    ) {
      setFulfillmentType(FulfillmentType.PICKUP);
      values.fulfillmentType = FulfillmentType.PICKUP;
      setSubmitting(true);
      FulfillmentInfoEditable.handleSubmit({
        values,
        updateFulfillmentGroup
      })
        .then(data => {
          if (!isEmpty(data)) {
            setCart(data);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  }, [
    submitting,
    open,
    setFulfillmentType,
    values,
    updateFulfillmentGroup,
    setCart
  ]);

  return (
    <Modal
      isOpen={open}
      ref={modalRef}
      dialogClassName="top-1/4"
      size={Modal.Size.LARGE}
    >
      <Modal.Header>
        <Modal.Header.Title className="flex items-center">
          <Icon
            className="text-yellow-700 mr-2 md:mr-4"
            name="exclamation-triangle"
          />
          <span className="text-yellow-700 font-medium lg:text-xl">
            {formatMessage(messages.modalTitle)}
          </span>
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body className="text-gray-700 bg-gray-100">
        <div className="px-2 py-1 text-yellow-700 text-sm leading-snug bg-yellow-100 border border-solid border-yellow-400 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
          <FormattedMessage
            {...messages.deliveryRadiusUnavailable}
            values={{
              breakLine: <br />,
              breakLineWide: <div />
            }}
          >
            {(...args) => (
              <div>
                {map(args, (arg, i) => {
                  if (isString(arg)) {
                    return <span key={i}>{arg}</span>;
                  }

                  if (arg.type === 'br') {
                    return <br key={i} />;
                  }

                  if (arg.type === 'div') {
                    return <div key={i} className="mb-2 lg:mb-3" />;
                  }

                  return <React.Fragment key={i}>{arg}</React.Fragment>;
                })}
              </div>
            )}
          </FormattedMessage>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center lg:justify-between">
        <SecondaryButton
          className="mr-2"
          onClick={() => {
            toggleOpen(false);
          }}
        >
          {formatMessage(messages.cancel)}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            toggleOpen(false);
            goToNextStep();
          }}
        >
          {formatMessage(messages.modifyPickup)}
        </PrimaryButton>
      </Modal.Footer>
    </Modal>
  );
};

FulfillmentInfoEditable.propTypes = {
  active: PropTypes.bool
};

export default withRouter(FulfillmentInfoEditable);
export { FulfillmentInfoEditable };
