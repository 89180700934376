/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { Currency } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

const FulfillmentMethodReadOnly = ({
  className,
  fulfillmentOption,
  messages,
  totalFulfillmentPrice
}) => {
  const formatMessage = useFormatMessage();

  return (
    <section className={className}>
      <h3 className="mt-2 mb-1 text-base text-gray-900 font-bold md:mt-0">
        {formatMessage(messages.deliveryMethodLabel)}
      </h3>
      <div>
        <span>
          {formatMessage(messages.deliveryMethodName, {
            name: fulfillmentOption.description
          })}
        </span>{' '}
        {!isEmpty(totalFulfillmentPrice) && (
          <span className="inline-flex">
            {'('}
            <Currency {...totalFulfillmentPrice} />
            {')'}
          </span>
        )}
      </div>
    </section>
  );
};

FulfillmentMethodReadOnly.propTypes = {
  className: PropTypes.string,
  fulfillmentOption: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  messages: PropTypes.object.isRequired,
  totalFulfillmentPrice: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired
  }).isRequired
};

export default FulfillmentMethodReadOnly;
export { FulfillmentMethodReadOnly };
