import { delay, get } from 'lodash';

const REVIEW_PATH = '/checkout/review';
const FULFILLMENT_INFO_PATH = '/checkout/fulfillment-info';

const goToNextCheckoutStep = function (history, nextUri, params, wait = 300) {
  return () => {
    const state = get(history, 'location.state', {});
    const nextState = { ...state, ...params };
    delay(() => {
      history.push(nextUri, nextState);
    }, wait);
  };
};

export default goToNextCheckoutStep;
export { REVIEW_PATH, FULFILLMENT_INFO_PATH };
