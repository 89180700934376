/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { default as qs } from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { Breadcrumbs } from 'app/common/components';
import { PriceContext, TenantContext } from 'app/common/contexts';
import {
  defaultRefreshEffectCondition,
  useCatalogSearchApi,
  useFormatMessage,
  useGtmPageView,
  useProductListImpressions,
  useRefreshEffect,
  useScrollToTop
} from 'app/common/hooks';

import { logError } from 'app/common/utils/ApiErrorUtils';
import {
  ResultsBody,
  ResultsHeader,
  ResultsHelmet
} from 'app/search-and-browse/shared/components';
import { ResultsLayoutProvider } from 'app/search-and-browse/shared/helpers';
import { useSearchRequestConfig } from 'app/search-and-browse/shared/hooks';

import messages from './SearchResultsPage.messages';

const SearchResultsPage = ({ location }) => {
  const searchQuery = get(location, 'search', '');

  useScrollToTop([location, searchQuery]);
  const { hasLoadedPriceLists } = React.useContext(PriceContext);
  const { application } = React.useContext(TenantContext);
  const { isAuthenticated } = React.useContext(AuthContext);
  const query = SearchResultsPage.getQuery(location);
  const formatMessage = useFormatMessage();
  const config = useSearchRequestConfig(searchQuery);
  const {
    error,
    exception,
    loading,
    response,
    sendCallback: fetchResults
  } = useCatalogSearchApi(config, false);

  useRefreshEffect(
    fetchResults,
    { config, hasLoadedPriceLists },
    refreshCondition,
    hasLoadedPriceLists
  );

  const breadcrumbs = React.useMemo(
    () => [
      {
        label: formatMessage(messages.breadcrumbLabel),
        searchQuery
      }
    ],
    [formatMessage, searchQuery]
  );

  if (error) {
    logError({
      ...exception,
      when: `fetching Products for Query "${query}"`
    });
  }

  const pageNumber = get(response, 'number', 0);
  const totalPages = get(response, 'totalPages', 0);
  const resultsTotal = get(response, 'totalElements', 0);
  const size = get(response, 'size', 15);
  const title = `"${query}" Search Results`;
  const products = get(response, 'content', []);
  const gtmProducts = React.useMemo(
    () =>
      products.map((product, i) => ({
        category: get(product, 'explicitCategoryNames[0]'),
        id: product.sku,
        name: product.description,
        position: i + pageNumber * size,
        price: get(product, 'priceInfo.price.amount', 0.0),
        quantity: 1,
        list: title
      })),
    [products, pageNumber, size, title]
  );
  const impressions = useProductListImpressions(gtmProducts, title);
  const dataLayer = React.useMemo(() => {
    return {
      ...impressions,
      search: {
        resultsTotal,
        dealer: get(application, 'name', 'Aurora Parts to Go'),
        retail: !isAuthenticated
      }
    };
  }, [application, impressions, isAuthenticated, resultsTotal]);

  useGtmPageView(title, dataLayer, loading || response === undefined);

  if (!loading && response !== undefined) {
    const { redirect: redirectData = {} } = response;

    if (!isEmpty(redirectData) && !isNil(redirectData.redirectUri)) {
      return <Redirect push={true} to={redirectData.redirectUri} />;
    }
  }

  return (
    <main className="container relative flex-grow min-h-screen p-4 sm:py-8 md:px-6 lg:px-8">
      <ResultsLayoutProvider breadcrumbs={breadcrumbs}>
        <ResultsHelmet
          metaTitle={formatMessage(messages.metaTitle, { query })}
          metaDescription={formatMessage(messages.metaDescription, { query })}
          pageNumber={pageNumber + 1}
          totalPages={totalPages}
          url="/search"
        />
        <header className="text-center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <ResultsHeader
            searchResponse={response}
            title={formatMessage(messages.title, { query })}
          />
        </header>
        <ResultsBody searchResponse={response} loading={loading} name={title} />
      </ResultsLayoutProvider>
    </main>
  );
};

function refreshCondition(dependencies, prevDependencies) {
  return (
    defaultRefreshEffectCondition(dependencies, prevDependencies) &&
    dependencies.hasLoadedPriceLists
  );
}

SearchResultsPage.getQuery = ({ search, state = {} }) => {
  let query = get(state, 'query');

  if (!isEmpty(query)) {
    return query;
  }

  return qs.parse(search).q;
};

SearchResultsPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      query: PropTypes.string
    })
  }).isRequired
};

export default withRouter(SearchResultsPage);
export { SearchResultsPage };
