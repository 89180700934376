/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { Environment } from 'app/common/services';
import React, { useContext, useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import { default as qs } from 'query-string';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { AuthContext } from 'app/auth/contexts';
import { CheckoutContext } from 'app/checkout/contexts';
import { CheckoutContextProvider, CheckoutHelmet } from 'app/checkout/helpers';
import { LoadingIcon, PrimaryButton } from 'app/common/components';
import {
  CartContext,
  PaymentContext,
  TenantContext
} from 'app/common/contexts';
import { useFormatMessage, useLocation, useMenuInfo } from 'app/common/hooks';
import { CSRRibbon } from 'app/csr/components';
import { Footer } from 'app/layout/components';

import {
  AccountInfo,
  CheckoutHeader,
  CheckoutItemSummary,
  Confirmation,
  FulfillmentInfo,
  FulfillmentInfoWithOptions,
  LocationInfo,
  PaymentInfo,
  Review,
  Summary
} from './components';
import messages from './CheckoutLayout.messages';
import environment from 'app/common/services/Environment/Environment';
import { setDeliveryOptionsStale } from 'app/checkout/contexts/reducers/checkoutReducer';
import { FulfillmentOptions } from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions';
import {
  CHECKOUT_PATHS,
  FULFILLMENT_INFO_PATH,
  FULFILLMENT_OPTIONS_PATH,
  LOCATION_INFO_PATH,
  PAYMENT_INFO_PATH,
  REVIEW_PATH
} from 'app/checkout/components/CheckoutLayout/CheckoutPaths';
import checkoutContext from 'app/checkout/contexts/CheckoutContext';

/**
 * Render component for the checkout layout containing the routing for the
 * various stages.
 *
 * @visibleName Checkout: Layout
 * @author [Nathan Moore](https://github.com/nathandmoore)
 */
const CheckoutLayout = ({ waitDefault = true }) => {
  const formatMessage = useFormatMessage();
  const { checkoutFooterMenuName } = useMenuInfo();
  const { cartStatus, isGuest } = useContext(CheckoutContext);
  const { resolving: cartResolving, cart } = useContext(CartContext);
  const { isAuthenticating, isAuthenticated } = useContext(AuthContext);
  const { resolving: resolvingTenant } = useContext(TenantContext);
  const location = useLocation();
  const search = qs.parse(get(location, 'search'));
  const dealer = search.location;
  const [wait, setWait] = useState(waitDefault);
  const { paypalEnabled } = useContext(PaymentContext);
  useResetCheckoutOnLocationChange();

  React.useEffect(() => {
    // show loading icon if any of the following are true
    const wait =
      isEmpty(cart) || // cart is empty
      cartResolving || // cart is resolving
      cartStatus === undefined || // cart status is undefined,
      resolvingTenant || // tenant is resolving
      isAuthenticating; // user is authenticating
    setWait(wait);
    // eslint-disable-next-line
  }, [
    cartStatus,
    cartResolving,
    isAuthenticating,
    resolvingTenant,
    checkoutContext,
    cart
  ]);

  if (!isEmpty(dealer)) {
    search[getApplicationParameter()] = dealer;
    search.location = undefined;
    setDeliveryOptionsStale(checkoutContext, true);
    return <Redirect to={{ ...location, search: qs.stringify(search) }} />;
  }

  const {
    paymentStepNumber,
    reviewStepNumber,
    fulfillmentInfoNextStepPath,
    fulfillmentOptionsNextStepPath
  } = getStepInfo(paypalEnabled);

  return (
    <div className="relative flex flex-col justify-between min-h-screen bg-gray-200">
      <CheckoutHelmet />
      <CSRRibbon />
      <CheckoutHeader />
      <Switch>
        <Route
          exact
          path={'/checkout/confirmation/:orderNumber'}
          component={Confirmation}
        />
        <Route>
          <main className="container relative flex flex-col flex-grow mx-auto px-2 py-4 md:px-6 lg:flex-row lg:px-8 lg:py-8">
            {wait ? (
              <CheckoutLayout.Loading />
            ) : (
              <>
                {!cartResolving &&
                !(
                  cartStatus === 'IN_PROCESS' ||
                  cartStatus === 'CSR_OWNED' ||
                  cartStatus === 'TEST'
                ) ? (
                  <CheckoutLayout.Empty formatMessage={formatMessage} />
                ) : (
                  <Switch>
                    <Route
                      exact
                      path={['/checkout', '/checkout/sign-in']}
                      component={AccountInfo}
                    />
                    <Route exact path={CHECKOUT_PATHS}>
                      {/* else authenticated or guest */}
                      {!isAuthenticated && !isGuest && (
                        <>
                          <Redirect to="/checkout/sign-in" />
                        </>
                      )}
                      <section className="lg:flex-auto lg:w-7/10 xl:w-3/4">
                        <CheckoutItemSummary />
                        <Route exact path={LOCATION_INFO_PATH}>
                          {({ match }) => (
                            <LocationInfo active={!!match} stageNumber={1} />
                          )}
                        </Route>
                        <Route exact path={FULFILLMENT_INFO_PATH}>
                          {/* We want to show this even if not a match */}
                          {({ match }) => (
                            <FulfillmentInfoSection
                              active={!!match}
                              stageNumber={2}
                              nextStepPath={fulfillmentInfoNextStepPath}
                            />
                          )}
                        </Route>
                        <Route exact path={FULFILLMENT_OPTIONS_PATH}>
                          {({ match }) => (
                            <FulfillmentOptions
                              active={!!match}
                              stageNumber={3}
                              nextStepPath={fulfillmentOptionsNextStepPath}
                            />
                          )}
                        </Route>
                        <Route exact path={PAYMENT_INFO_PATH}>
                          {({ match }) => (
                            <PaymentInfo
                              active={!!match}
                              stageNumber={paymentStepNumber}
                            />
                          )}
                        </Route>
                        <Route exact path={REVIEW_PATH}>
                          {({ match }) => (
                            <Review
                              active={!!match}
                              stageNumber={reviewStepNumber}
                            />
                          )}
                        </Route>
                      </section>
                      <section className="relative lg:flex-auto lg:w-3/10 lg:pl-4 xl:w-1/4">
                        <Summary />
                      </section>
                    </Route>
                    <Route>
                      <Redirect to="/checkout" />
                    </Route>
                  </Switch>
                )}
              </>
            )}
          </main>
        </Route>
      </Switch>
      <Footer menuName={checkoutFooterMenuName} />
    </div>
  );
};

CheckoutLayout.Empty = ({ formatMessage }) => {
  return (
    <header className="relative flex flex-col flex-grow items-center">
      <h1 className="mb-8 capitalize text-3xl leading-tight text-center">
        {formatMessage(messages.empty)}
      </h1>
      <PrimaryButton to="/" size={PrimaryButton.Size.LARGE}>
        {formatMessage(messages.continueShopping)}
      </PrimaryButton>
    </header>
  );
};

CheckoutLayout.Loading = () => (
  <div className="flex flex-grow items-start justify-center">
    <LoadingIcon />
  </div>
);

const CheckoutContainer = () => (
  <CheckoutContextProvider>
    <CheckoutLayout />
  </CheckoutContextProvider>
);

function getApplicationParameter() {
  return Environment.get(
    'tenant.resolver.application.parameter',
    'application'
  );
}

/**
 * Monitors if the user changes location during the checkout process.
 * If the user selects a different store and is on fulfillment,
 * payment, or review, we send the user back to the first step of the checkout process.
 */
const useResetCheckoutOnLocationChange = () => {
  const history = useHistory();
  const location = useLocation();
  const { checkoutDispatch } = useContext(CheckoutContext);
  useEffect(() => {
    const resetCheckoutPaths = [
      '/checkout/fulfillment-info',
      '/checkout/payment-info',
      '/checkout/review'
    ];
    const applicationParam = getApplicationParameter();

    const params = new URLSearchParams(location.search);
    if (
      resetCheckoutPaths.includes(location.pathname) &&
      params.get(applicationParam)
    ) {
      setDeliveryOptionsStale(checkoutDispatch, true);
      history.push('/checkout/location-info', { search: location.search });
    }
    // eslint-disable-next-line
  }, [location, history]);
};

const FulfillmentInfoSection = ({ active, stageNumber, nextStepPath }) => {
  if (isFulfillmentServiceEnabled()) {
    return (
      <FulfillmentInfoWithOptions
        active={active}
        stageNumber={stageNumber}
        nextStepPath={nextStepPath}
      />
    );
  } else {
    return <FulfillmentInfo active={active} stageNumber={stageNumber} />;
  }
};

const getStepInfo = function (paypalEnabled) {
  const fulfillmentServiceEnabled = isFulfillmentServiceEnabled();
  let paymentStepNumber = 3;
  let reviewStepNumber = 3;
  let fulfillmentInfoNextStepPath = REVIEW_PATH;
  let fulfillmentOptionsNextStepPath = REVIEW_PATH;
  if (paypalEnabled) {
    reviewStepNumber += 1;
    fulfillmentOptionsNextStepPath = PAYMENT_INFO_PATH;
    fulfillmentInfoNextStepPath = PAYMENT_INFO_PATH;
  }
  if (fulfillmentServiceEnabled) {
    reviewStepNumber += 1;
    paymentStepNumber += 1;
    fulfillmentInfoNextStepPath = FULFILLMENT_OPTIONS_PATH;
  }
  return {
    paymentStepNumber,
    reviewStepNumber,
    fulfillmentInfoNextStepPath,
    fulfillmentOptionsNextStepPath
  };
};

function isFulfillmentServiceEnabled() {
  return environment.get('FULFILLMENT_SERVICE_ENABLED', 'true') === 'true';
}

Summary.ItemsSummary = CheckoutItemSummary;

export default CheckoutContainer;
export { CheckoutLayout };
